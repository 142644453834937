import(/* webpackMode: "eager", webpackExports: ["WebVitals"] */ "/app/app/components/web-vitals.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LiveStream"] */ "/app/app/live-steam-banner/live-stream.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageClient"] */ "/app/app/page-client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QueryProvider"] */ "/app/app/providers/query-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/customLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MegaMenuWrapper"] */ "/app/components/server/MegaMenuWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SocialIcons"] */ "/app/components/socialIcons/socialIcons.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppInsightsProvider"] */ "/app/context/app-insight-client.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.1.6_next@14.2.15_@babel+core@7.25.9_@opentelemetry+api@1.9.0_@playwrig_6q2f77dnslte4wpj4mbxmi6tzu/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.1.6_next@14.2.15_@babel+core@7.25.9_@opentelemetry+api@1.9.0_@playwrig_6q2f77dnslte4wpj4mbxmi6tzu/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.1.6_next@14.2.15_@babel+core@7.25.9_@opentelemetry+api@1.9.0_@playwrig_6q2f77dnslte4wpj4mbxmi6tzu/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.59.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.59.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@tanstack+react-query@5.59.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.59.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.59.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.59.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.59.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.59.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.59.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.59.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.59.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.59.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/app/node_modules/.pnpm/@tanstack+react-query@5.59.16_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.9_@opentelemetry+api@1.9.0_@playwright+test@1.48.2_react-dom@18_ubouu23djzrjbbdvlr37plw6sq/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.9_@opentelemetry+api@1.9.0_@playwright+test@1.48.2_react-dom@18_ubouu23djzrjbbdvlr37plw6sq/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.9_@opentelemetry+api@1.9.0_@playwright+test@1.48.2_react-dom@18_ubouu23djzrjbbdvlr37plw6sq/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.9_@opentelemetry+api@1.9.0_@playwright+test@1.48.2_react-dom@18_ubouu23djzrjbbdvlr37plw6sq/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
